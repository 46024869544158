import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { Components } from '../../server';
import { colors, theme } from '../../theme';
import { setBgColor, setBorderColor } from '../../lib/set-color';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';

export const AssessmentTypePicker: React.FC<{
  options: Components.Schemas.AssessmentType['options'];
  selected: number | undefined;
  onSelect: (id: number) => void;
}> = React.memo(({ options, selected, onSelect }) => {
  const { isDesktop } = useDesktopStyles();
  if (!options || !options.length) {
    return null;
  }

  return (
    <View
      style={[
        styles.container,
        {
          gap: isDesktop ? 4 : 0,
        },
      ]}
    >
      {options.map((item, i) => (
        <TouchableOpacity
          style={{ flex: 1 }}
          key={item.id}
          onPressIn={() => onSelect(item.id)}
        >
          <BubbleScaleUpAnimation flexOne delay={(i + 1) * 200}>
            <Card
              key={item.id}
              style={[
                styles.card,
                { padding: isDesktop ? 7 : 5, paddingVertical: 8 },
                selected === item.id && {
                  borderColor: setBorderColor(item),
                  backgroundColor: setBgColor(item),
                },
                i !== 0 && styles.margin,
              ]}
            >
              <Text style={styles.text}>
                {item.label
                  .replace(', ', ',\n')
                  .replace(' s ', ' s\xa0')
                  .replace(' na ', ' na\xa0')}
              </Text>
            </Card>
          </BubbleScaleUpAnimation>
        </TouchableOpacity>
      ))}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  margin: {
    marginLeft: 3,
  },
  card: {
    flex: 1,
    marginVertical: 6,
    borderStyle: 'solid',
    borderWidth: 3,
    borderColor: '#eee',
  },
  selected: {
    borderColor: theme.colors.primary,
  },
  text: {
    flex: 1,
    color: colors.text,
    fontSize: 14,
    textAlign: 'center',
    textAlignVertical: 'center',
  },
});
