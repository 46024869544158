import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Caption, Card, Text } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { ChildType } from '../../store';
import { colors } from '../../theme';
import { CustomCheckbox } from '../ui/CustomCheckbox';
import { FadeInFromTopAnimation } from '../animations/FadeInFromTopAnimation';

export const ChildListItem: React.FC<{
  deleteMode?: boolean;
  selected?: boolean;
  item: ChildType;
  hideCheckbox?: boolean;
  onPress?: (id: number) => void;
  onPressCheck?: (id: number) => void;
}> = React.memo(function ChildListItem({
  deleteMode,
  hideCheckbox,
  selected,
  item,
  onPress,
  onPressCheck,
}) {
  const _onPress = React.useCallback(() => {
    onPress && onPress(item.id);
  }, [onPress, item]);

  const _onPressCheck = React.useCallback(() => {
    onPressCheck && onPressCheck(item.id);
  }, [item, onPressCheck]);

  const getAgeNumber = React.useCallback(() => {
    const age = item.ageShortString;
    const ageNumber = age.replace(/[^0-9]/g, '');
    return ageNumber.split('').join(',');
  }, [item]);

  return (
    <View
      style={{
        width: '50%',
        paddingRight: 4,
        paddingBottom: 4,
      }}
      nativeID='child_list_item'
    >
      <FadeInFromTopAnimation delay={0} duration={400}>
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={_onPress}
          onLongPress={_onPressCheck}
        >
          <Card
            style={[
              styles.item,
              item.gender === 'M' && styles.boy,
              item.gender === 'F' && styles.girl,
            ]}
          >
            <View style={styles.row}>
              <Text style={styles.childName}>
                {item.shortName}
                <Caption style={{ color: 'white' }}>
                  {' '}
                  {`(${getAgeNumber()})`}
                </Caption>
              </Text>
              {!hideCheckbox && (
                <>
                  {deleteMode ? (
                    <Icon
                      name="close-circle"
                      color="white"
                      size={24}
                      onPress={_onPressCheck}
                    />
                  ) : (
                    <CustomCheckbox
                      color="white"
                      checked={selected}
                      onPress={_onPressCheck}
                    />
                  )}
                </>
              )}
            </View>
          </Card>
        </TouchableOpacity>
      </FadeInFromTopAnimation>
    </View>
  );
});

const styles = StyleSheet.create({
  item: {
    backgroundColor: colors.green,
    paddingHorizontal: 8,
    borderRadius: 10,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  childName: {
    paddingVertical: 10,
    paddingHorizontal: 2,
    maxWidth: '92%',

    fontWeight: '500',
    color: 'white',
  },
  boy: {
    backgroundColor: colors.blue,
  },
  girl: {
    backgroundColor: colors.red,
  },
});
