import { configureFonts, MD2LightTheme, MD2Theme } from 'react-native-paper';

export const colors = {
  orange: '#eb803f',
  darkRed: '#D04646',
  goldenYellow: '#E7A91E',
  lightOrange: '#FDBE9E',
  veryLightOrange: '#FFEDE4',
  red: '#D13232',
  blue: '#4E6ACF',
  lightBlue: '#92caff',
  lightGrey: '#F2F2F2',
  lightGrey2: '#D3D3D3',
  veryLightGrey: '#f7f9fa',
  veryLightBlue: '#e6f0ff',
  lightDarkRed: '#E9A0A0',
  veryLightDarkRed: '#FFEBE6',
  disabled: '#CC8181',
  yellowGreen: '#C8BE61',
  lightYellowGreen: '#B3C861',
  green: '#91c861',
  text: '#434343',
  yellow: '#e7a91e',
  veryLightYellow: '#FFEDC5',
  veryLightGreen: '#E6F0E6',
  veryLightRed: '#FFDDDD',
  transparent: 'transparent',
  grey: '#7a7a7a',
  white: '#fff',
};

export const theme: MD2Theme = {
  ...MD2LightTheme,
  roundness: 10,

  colors: {
    ...MD2LightTheme.colors,
    text: colors.text,
    background: '#FFF7F3',
    disabled: colors.disabled,

    primary: colors.darkRed,
    accent: colors.orange,
    error: colors.red,
  },
  fonts: configureFonts({
    isV3: false,
    config: {
      android: {
        regular: {
          fontFamily: 'Poppins-Regular',
          fontWeight: 'normal',
        },
        medium: {
          fontFamily: 'Poppins-Medium',
          fontWeight: 'normal',
        },
        light: {
          fontFamily: 'Poppins-Light',
          fontWeight: 'normal',
        },
        thin: {
          fontFamily: 'Poppins-Thin',
          fontWeight: 'normal',
        },
      },
      ios: {
        regular: {
          fontFamily: 'Poppins-Regular',
          fontWeight: 'normal',
        },
        medium: {
          fontFamily: 'Poppins-Medium',
          fontWeight: 'normal',
        },
        light: {
          fontFamily: 'Poppins-Light',
          fontWeight: 'normal',
        },
        thin: {
          fontFamily: 'Poppins-Thin',
          fontWeight: 'normal',
        },
      },
      web: {
        regular: {
          fontFamily: 'Poppins-Regular',
          fontWeight: 'normal',
        },
        medium: {
          fontFamily: 'Poppins-Medium',
          fontWeight: 'normal',
        },
        light: {
          fontFamily: 'Poppins-Light',
          fontWeight: 'normal',
        },
        thin: {
          fontFamily: 'Poppins-Thin',
          fontWeight: 'normal',
        },
      },
    },
  }),
};

export const navigationTheme = {
  dark: false,

  colors: {
    primary: colors.darkRed,
    background: '#FFF1EA', //rgb(242, 242, 242)',
    card: 'rgb(255, 255, 255)',
    text: 'rgb(28, 28, 30)',
    border: 'rgb(199, 199, 204)',
    notification: 'rgb(255, 69, 58)',
  },
};
