import React from 'react';
import { ClassHomeProps } from '../lib/navigation';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import {
  appReadyState,
  classChildrenState,
  classesState,
  classStatsState,
  dataReadyState,
} from '../store';
import { CustomHeader } from '../components/layout/CustomHeader';
import { HeaderMenu } from '../components/layout/HeaderMenu';
import { ClassHeader } from '../components/class/ClassHeader';
import { Dimensions, StyleSheet } from 'react-native';
import { Card, Headline, Icon, Text } from 'react-native-paper';
import { Button } from '../components/ui/Button';
import { Scroller } from '../components/ui/Scroller';
import { colors, theme } from '../theme';
import { Logo } from '../components/ui/Logo';
import { Background } from '../components/ui/Background';
import { View } from 'react-native';
import { BubbleScaleUpAnimation } from '../components/animations/BubbleScaleUpAnimation';
import FadeInFromLeftOrRight from '../components/animations/FadeInFromLeftOrRightAnimation';
import { FadeInFromTopAnimation } from '../components/animations/FadeInFromTopAnimation';
import BouncingChevronRight from '../components/ui/BouncingChevronRight';
import usePressEffect from '../hooks/use-press-effect';
import { isWeb } from '../lib/is-web';
import { useDesktopStyles } from '../hooks/use-desktop-styles';
import { Spinner } from '../components/ui/Spinner';
import { useLoadingState } from '../hooks/use-loading-states';
import { useAuth } from '../hooks/use-auth';


export const ClassHomeScreen = React.memo(function ClassHomeScreen({
  route,
  navigation,
}: ClassHomeProps) {
  const { classId = 0 } = route.params || {};

  const classes = useRecoilValue(classesState);
  const children = useRecoilValue(classChildrenState(classId));
  const statsLoadable = useRecoilValueLoadable(classStatsState({ classId }));
  const { onCardPress, pressedCardIndex } = usePressEffect();
  const deviceWidth = React.useMemo(() => Dimensions.get('window').width, []);
  const { averageFillRate = 0 } =
    statsLoadable.state === 'hasValue' ? statsLoadable.contents : {};
  const { logOut } = useAuth();
  const { isDesktop } = useDesktopStyles();

  const {
    isLoading,
    showDelayedMessage,
    showReconnectButton,
    resetLoadingState,
  } = useLoadingState();
  React.useEffect(() => {
    if (!classId) {
      const firstClass = Object.values(classes)[0]?.id;
      if (firstClass) {
        navigation.setParams({ classId: firstClass });
      }

      if (!firstClass && !isWeb) {
        navigation.navigate('Profile');
      }
    }
  }, [classId, classes, navigation]);

  const handleReconnect = () => {
    logOut();
    resetLoadingState();
  };

  const renderTitle = (icon: string, text: string, delay: number) => {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
        <BubbleScaleUpAnimation delay={delay} duration={500}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: colors.veryLightOrange,
              padding: 8,
              marginTop: 2,
              borderRadius: 100,
              gap: 10,
            }}
          >
            <Icon source={icon} size={24} color={colors.orange} />
          </View>
        </BubbleScaleUpAnimation>
        <Text style={{ marginLeft: 0, fontSize: 20 }}>{text}</Text>
      </View>
    );
  };

  const cards = [
    {
      title: renderTitle('account-group-outline', 'Děti', 800),
      body: (
        <View style={{ flexDirection: 'row' }}>
          <View>
            <Text style={{ fontSize: 16 }}>
              {children.length}{' '}
              {children.length === 1
                ? 'dítě'
                : children.length > 1 && children.length < 4
                  ? 'děti'
                  : 'dětí'}
              {', '}
              {Math.round(averageFillRate * 1000) / 10}% vyplněnost
            </Text>
            <Text>Podívejte se na konkrétní dítě.</Text>
          </View>

          {/* <BouncingChevronRight isPressed={isPressed} /> */}
        </View>
      ),
      action: {
        title: 'Poslední hodnocení',
        onPress: React.useCallback(
          () => navigation.navigate('Assessments'),
          [navigation],
        ),
      },
      onPress: React.useCallback(
        () =>
          navigation.navigate('Class', { classId, screen: 'ClassChildren' }),
        [navigation, classId],
      ),
    },
    {
      title: renderTitle('briefcase-outline', 'Klokanův kufr', 1100),
      body: (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ width: isDesktop ? 'auto' : deviceWidth - 100 }}>
            <Text>Projděte si diagnostiku Klokanova kufru.</Text>
            <Text>
              Seznamte se s oblastmi k hodnocení a s věkovým rozlišením.
            </Text>
          </View>
          {/* <BouncingChevronRight isPressed={isPressed} /> */}
        </View>
      ),
      onPress: React.useCallback(
        () =>
          navigation.navigate('Class', {
            classId,
            screen: 'ClassCategorySelect',
          }),
        [navigation, classId],
      ),
      action: {
        title: 'Vyplnit hodnocení',
        onPress: React.useCallback(
          () => navigation.navigate('CreateAssessment', { classId }),
          [navigation, classId],
        ),
      },
    },
    {
      title: renderTitle(
        'order-bool-descending-variant',
        'Statistika tříd',
        1400,
      ),
      body: (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ width: isDesktop ? 'auto' : deviceWidth - 100 }}>
            <Text>Podívejte se na hodnocení vaší třídy.</Text>
            <Text>Naplánujte si podle grafu další výuku.</Text>
          </View>
          {/* <BouncingChevronRight isPressed={isPressed} /> */}
        </View>
      ),
      onPress: React.useCallback(
        () => navigation.navigate('Class', { classId, screen: 'ClassStats' }),
        [navigation, classId],
      ),
      action: {
        title: 'Zobrazit',
        onPress: React.useCallback(
          () => navigation.navigate('Class', { classId, screen: 'ClassStats' }),
          [navigation, classId],
        ),
      },
    },
    {
      title: renderTitle('note-multiple-outline', 'Poznámky', 1000),
      body: (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View>
            <Text>Zapište si myšlenky k vaší třídě</Text>
            <Text>a kdykoliv si je zobrazte.</Text>
          </View>
          {/* <BouncingChevronRight isPressed={pressedCardIndex === i} /> */}
        </View>
      ),
      onPress: React.useCallback(
        () => navigation.navigate('Class', { classId, screen: 'ClassNotes' }),
        [navigation, classId],
      ),
      action: {
        title: 'Přidat poznámku',
        onPress: React.useCallback(
          () => navigation.navigate('EditClassroomNote', { classId }),
          [navigation, classId],
        ),
      },
    },
  ];

  if (isLoading && isWeb) {
    return (
      <View style={styles.loadingContainer}>
        <View style={styles.loadingInnerContainer}>
          <Spinner size="large" color={theme.colors.primary} />
          {showDelayedMessage && (
            <Text style={styles.delayedMessage}>
              Stahování dat může chvilku trvat... Prosím, vyčkejte.
            </Text>
          )}
          {showReconnectButton && (
            <Button
              mode="contained"
              onPress={handleReconnect}
              style={styles.reconnectButton}
            >
              Zkusit přihlásit znovu
            </Button>
          )}
        </View>
      </View>
    );
  }

  if (!classId) {
    return null;
  }

  return (
    <>
      <CustomHeader
        title={
          <View>
            <ClassHeader
              selected={classId}
              selectClass={(classId) => navigation.setParams({ classId })}
            />
          </View>
        }
        menu={<View nativeID="header_menu"><HeaderMenu navigation={navigation} /></View>}
      />
      <Background>
        <Scroller style={{ flexDirection: 'column', gap: 14 }}>
          <BubbleScaleUpAnimation delay={0} duration={500}>
            <View
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Logo />
            </View>
          </BubbleScaleUpAnimation>

          <FadeInFromTopAnimation delay={0} duration={400}>
            <View
              style={{
                alignItems: 'center',
              }}
            >
              <Button
                mode="contained"
                labelStyle={styles.buttonLabelContained}
                style={styles.button}
                nativeID="start_assessment_button"
                onPress={() =>
                  navigation.navigate('Class', {
                    classId,
                    screen: 'ClassChildren',
                  })
                }
              >
                Začněte vyplňovat
              </Button>
              <Button
                mode="outlined"
                labelStyle={styles.buttonLabel}
                nativeID='last_assessment_button'
                style={styles.button}
                onPress={() => navigation.navigate('Assessments')}
              >
                Poslední hodnocení
              </Button>
            </View>
          </FadeInFromTopAnimation>

          <View>
            {cards.map((card, i) => (
              <FadeInFromLeftOrRight
                key={i}
                delay={(i + 1) * 350}
                duration={400}
              >
                <Card
                  key={i}
                  nativeID={
                    i === 0
                      ? 'children_card'
                      : i === 1
                      ? 'kufr_card'
                      : i === 2
                      ? 'stats_card'
                      : i === 3
                      ? 'notes_card'
                      : undefined
                  }
                  style={{
                    marginVertical: 5,
                    paddingVertical: 4,
                    paddingTop: 12,
                    borderColor: theme.colors.primary,
                    borderWidth: 1,
                  }}
                  elevation={2}
                  onPress={() => onCardPress(i, true, card.onPress)}
                >
                  <Card.Title title={card.title} />

                  <Card.Content style={styles.flexBetween}>
                    {card.body}
                    <BouncingChevronRight isPressed={pressedCardIndex === i} />
                  </Card.Content>
                </Card>
              </FadeInFromLeftOrRight>
            ))}
          </View>
          <View style={{ height: 20 }} />
        </Scroller>
      </Background>
    </>
  );
});

const styles = StyleSheet.create({
  button: {
    marginVertical: 0,
    flex: 1,
    width: '75%',
    height: 48,
    justifyContent: 'center',
    marginTop: 10,
    // paddingVertical: 6,
    // paddingHorizontal: 16,
    borderColor: theme.colors.primary,
  },
  buttonLabelContained: {
    color: 'white',
    fontWeight: '600',
    fontSize: 15,
  },
  buttonLabel: {
    color: theme.colors.primary,
    fontWeight: '600',
    fontSize: 15,
  },
  flexBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
  },
  loadingInnerContainer: {
    justifyContent: 'center',
    padding: 16,
    alignItems: 'center',
    gap: 10,
  },

  delayedMessage: {
    fontSize: 16,
    color: theme.colors.text,
  },
  reconnectButton: {
    marginTop: 10,
  },
});
