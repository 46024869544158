import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Slider from '@react-native-community/slider';
import { format, isValid, subMonths, subYears } from 'date-fns';
import { colors } from '../../theme';
import { Button } from 'react-native-paper';
import { useDebounce } from '../../hooks/use-debounce';

type DateSliderProps = {
  startDate: Date;
  endDate: Date;
  currentDate: Date;
  onDateChange: (date: Date) => void;
};

const DateSlider = ({
  startDate,
  endDate,
  currentDate,
  onDateChange,
}: DateSliderProps) => {
  const calculateSliderValue = React.useCallback(
    (date: Date) => {
      return (
        (date.getTime() - startDate.getTime()) /
        (endDate.getTime() - startDate.getTime())
      );
    },
    [startDate, endDate],
  );
  const [sliderValue, setSliderValue] = React.useState(
    calculateSliderValue(currentDate),
  );

  const debouncedDateChange = useDebounce((value: number) => {
    const date = new Date(
      startDate.getTime() + (endDate.getTime() - startDate.getTime()) * value,
    );
    onDateChange(date);
  }, 175);

  const handleSliderChange = React.useCallback(
    (value: number) => {
      setSliderValue(value);
      debouncedDateChange(value);
    },
    [debouncedDateChange],
  );

  const formatDate = React.useCallback((date: Date) => {
    if (!isValid(date)) {
      return '';
    }
    return format(date, 'dd.MM.yyyy');
  }, []);

  const handleTimeJump = React.useCallback(
    (months: number) => {
      const newDate =
        months === 12 ? subYears(new Date(), 1) : subMonths(new Date(), months);

      const clampedDate = new Date(
        Math.max(
          startDate.getTime(),
          Math.min(newDate.getTime(), endDate.getTime()),
        ),
      );
      const newSliderValue = calculateSliderValue(clampedDate);

      setSliderValue(newSliderValue);
      onDateChange(clampedDate);
    },
    [startDate, endDate, calculateSliderValue, onDateChange],
  );

  const isButtonDisabled = (months: number) => {
    const jumpDate =
      months === 12 ? subYears(new Date(), 1) : subMonths(new Date(), months);
    return jumpDate < startDate;
  };

  return (
    <View nativeID="date_slider" style={styles.container}>
      <Slider
        style={styles.slider}
        minimumValue={0}
        maximumValue={1}
        value={sliderValue}
        onValueChange={handleSliderChange}
        maximumTrackTintColor={colors.lightGrey2}
        minimumTrackTintColor={colors.orange}
        thumbTintColor={colors.orange}
      />
      <Text style={styles.dateText}>{formatDate(currentDate)}</Text>
      <View style={styles.buttonContainer}>
        <Button
          mode="contained-tonal"
          uppercase={false}
          onPress={() => handleTimeJump(12)}
          disabled={isButtonDisabled(12)}
          style={styles.button}
        >
          1 r.
        </Button>

        <Button
          mode="contained-tonal"
          uppercase={false}
          onPress={() => handleTimeJump(6)}
          disabled={isButtonDisabled(6)}
          style={styles.button}
        >
          6 m.
        </Button>
        <Button
          mode="contained-tonal"
          uppercase={false}
          onPress={() => handleTimeJump(3)}
          disabled={isButtonDisabled(3)}
          style={styles.button}
        >
          3 m.
        </Button>
        <Button
          mode="contained-tonal"
          uppercase={false}
          onPress={() => handleTimeJump(0)}
          disabled={isButtonDisabled(0)}
          style={styles.button}
        >
          nyní
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 18,
  },
  slider: {
    width: '100%',
    height: 40,
  },
  dateText: {
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 16,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 10,
  },
  button: {
    marginHorizontal: 5,
  },
});

export default DateSlider;
