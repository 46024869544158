import React from 'react';
import { View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { colors } from '../../theme';

export const CustomCheckbox = React.memo(function CustomCheckbox({
  checked = false,
  disabled = false,
  color = colors.darkRed,
  disabledColor = colors.disabled,
  style = {},
  onPress,
}: {
  checked?: boolean;
  disabled?: boolean;
  color?: string;
  disabledColor?: string;
  onPress: (checked: boolean) => void;
  style?: any;
}) {
  return (
    <View style={[{ justifyContent: 'center', marginLeft: 5 }, style]}>
      <Icon.Button
        name={checked ? 'check-circle' : 'checkbox-blank-circle-outline'}
        style={{ margin: 0, padding: 0 }}
        iconStyle={{
          margin: 0,
          padding: 0,
          alignSelf: 'center',
          marginRight: 0,
          color: disabled ? disabledColor : color,
        }}
        onPress={() => onPress(!checked)}
        color="#006bcc"
        backgroundColor="rgba(0,0,0,0)"
        underlayColor="rgba(0,0,0,0)"
        disabled={disabled}
        size={20}
      />
    </View>
  );
});
