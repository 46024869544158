import React from 'react';
import { useIsFocused } from '@react-navigation/native';
import { ChildCategoryProps } from '../lib/navigation';
import { Background } from '../components/ui/Background';
import { useMultiFABScroll } from '../components/multifab/MultiFABContext';
import { IconButton, Portal } from 'react-native-paper';
import { MultiFAB } from '../components/multifab/MultiFAB';
import { useRecoilValue } from 'recoil';
import {
  categoryState,
  childAssessmentsByTaskState,
  childState,
} from '../store';
import { ChildTaskPicker } from '../components/child/ChildTaskPicker';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import RollOptions from '../components/ui/RollOptions';
import { colors } from '../theme';
import { showToast } from '../lib/toast';
import ChildTypeGender from '../components/child/ChildTypeGender';
import { isWeb } from '../lib/is-web';

export const ChildCategoryScreen = React.memo(function ChildCategoryScreen({
  route,
  navigation,
}: ChildCategoryProps) {
  const { childId, categoryId, subcategoryId } = route.params;
  const isFocused = useIsFocused();
  const { setStatus } = useMultiFABScroll();
  const [selected, onSelect] = React.useState<number[]>([]);
  const child = useRecoilValue(childState(childId));
  const assessments = useRecoilValue(childAssessmentsByTaskState(childId));
  const category = useRecoilValue(categoryState(categoryId));
  const [showExtendedData, setShowExtendedData] = React.useState(false);
  const onPress = React.useCallback(
    (id: number) => {
      navigation.push('ChildTask', { childId, taskId: id });
    },
    [navigation, childId],
  );

  const onDeleteAllSelected = React.useCallback(() => {
    onSelect([]);
    showToast(
      'Vybrané úlohy byly odebrány',
      'Můžete vybrat nové úlohy',
      'success',
      3000,
    );
  }, [onSelect]);

  const classId = child?.classroom;

  React.useEffect(() => {
    if (isFocused && classId) {
      if (selected.length > 0) {
        setStatus({
          multi: true,
          initial: {
            classId,
            childIds: [childId],
            categoryId,
            taskIds: selected,
          },
        });
      } else {
        setStatus({ initial: { classId, childIds: [childId], categoryId } });
      }
    }
  }, [isFocused, setStatus, childId, categoryId, selected, classId]);

  const isMaleGender = child?.gender === 'M';
  return (
    <Background>
      <TouchableOpacity
        style={[styles.row]}
        onPress={() =>
          navigation.navigate('Child', {
            childId: child?.id as number,
          })
        }
      >
        <View nativeID='child_select' style={styles.row}>
          <ChildTypeGender
            delay={600}
            isGender={isMaleGender}
            child={child}
            isChevronRight
          />
        </View>
      </TouchableOpacity>

      <RollOptions
        setShowData={setShowExtendedData}
        showData={showExtendedData}
        name={category?.label}
        style={{ marginTop: isWeb ? 0 : -10, marginBottom: isWeb ? 0 : -4 }}
      />

      <ChildTaskPicker
        {...{
          assessments,
          onPress,
          categoryId,
          subcategoryId,
          childId,
          classId,
          selected,
          onSelect,
          showExtendedData,
          isHistoryButton: true,
        }}
      />

      {selected.length >= 2 && !isWeb && (
        <IconButton
          style={styles.deleteButton}
          icon="delete"
          iconColor="white"
          size={24}
          animated
          android_ripple={{ color: 'rgba(0, 0, 0, .32)' }}
          rippleColor={colors.veryLightRed}
          onPress={onDeleteAllSelected}
        />
      )}

      <Portal>
        <MultiFAB visible={isFocused} />
      </Portal>
    </Background>
  );
});

const styles = StyleSheet.create({
  deleteButton: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 56,
    height: 56,
    backgroundColor: colors.red,
    color: colors.white,
    margin: 16,
    borderRadius: 50,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    paddingBottom: 0,
  },
});
