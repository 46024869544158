import React from 'react';
import { Portal } from 'react-native-paper';
import { ChildNotes } from '../components/child/ChildNotes';
import { ChildProps, ChildTab } from '../lib/navigation';
import { ChildOverview } from '../components/child/ChildOverview';
import { ChildIDContext } from '../lib/contexts';
import { ChildCategorySelect } from '../components/child/ChildCategorySelect';
import { MultiFAB } from '../components/multifab/MultiFAB';
import { useIsFocused } from '@react-navigation/native';
import { colors, theme } from '../theme';


export const ChildScreen = React.memo(function ChildScreen({
  route,
}: ChildProps) {
  const isFocused = useIsFocused();

  return (
    
    <ChildIDContext.Provider value={route.params.childId}>
      <ChildTab.Navigator
        // shifting={false}
        // sceneAnimationEnabled={false}

        barStyle={{ backgroundColor: colors.lightOrange }}
        activeColor={theme.colors.primary}
        inactiveColor="rgba(255, 255, 255, 0.7)"
      >
        <ChildTab.Screen
          name="ChildOverview"
          component={ChildOverview}
          options={{ title: 'Profil', tabBarIcon: 'account' }}
        />
        <ChildTab.Screen
          name="ChildCategorySelect"
          component={ChildCategorySelect}
          options={{ title: 'Klokanův kufr', tabBarIcon: 'briefcase-outline' }}
        />
        <ChildTab.Screen
          name="ChildNotes"
          component={ChildNotes}
          options={{ title: 'Poznámky', tabBarIcon: 'note-multiple-outline' }}
        />
      </ChildTab.Navigator>
      <Portal>
        <MultiFAB visible={isFocused} tabs />
      </Portal>
    </ChildIDContext.Provider>
  );
});
