import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import {
  TextInput,
  Appbar,
  Caption,
  Subheading,
  Card,
  Divider,
  Text,
  IconButton,
} from 'react-native-paper';
import { useRecoilValue } from 'recoil';
import { EditAssessmentProps } from '../lib/navigation';
import { Components } from '../server';
import {
  assessmentState,
  assessmentTypeState,
  categoriesState,
  childState,
  subcategoriesState,
  taskState,
  userState,
} from '../store';
import { format } from 'date-fns';
import { useAssessmentOps } from '../actions';
import { useAssertOnline } from '../utils';
import { icons } from '../components/icons';
import { CustomHeader } from '../components/layout/CustomHeader';
import { Scroller } from '../components/ui/Scroller';
import { colors, theme } from '../theme';
import { AssessmentTypePicker } from '../components/assessments/AssessmentTypePicker';
import { DatePicker } from '../components/ui/DatePicker';
import { Button } from '../components/ui/Button';
import { showToast } from '../lib/toast';
import ChildTypeGender from '../components/child/ChildTypeGender';
import { FadeInFromTopAnimation } from '../components/animations/FadeInFromTopAnimation';
import { BackgroundKeyboard } from '../components/ui/BackgroundKeyboard';
import DeleteItemDialog from '../components/ui/DeleteItemDialog';
import IconWrapper from '../components/ui/IconWrapper';


type AssessmentData = Omit<Components.Schemas.Assessment, 'id'>;

export const EditAssessmentScreen = React.memo(function EditAssessmentScreen({
  route,
  navigation,
}: EditAssessmentProps) {
  const { assessmentId } = route.params;
  const assertOnline = useAssertOnline();
  const ops = useAssessmentOps();
  const categories = useRecoilValue(categoriesState);
  const subcategories = useRecoilValue(subcategoriesState);
  const initial = useRecoilValue(assessmentState(assessmentId));
  const task = useRecoilValue(taskState(initial?.task || 0));
  const child = useRecoilValue(childState(initial?.child || 0));
  const assessmentType = useRecoilValue(
    assessmentTypeState(task?.assessment_type || 0),
  );
  const user = useRecoilValue(userState(initial?.assessed_by || 0));
  const [deleteDialogVisible, setDeleteDialogVisible] = React.useState(false);


  const CategoryIcon =
    icons[
      categories[subcategories[task?.subcategory || 0]?.parent_category || 0]
        ?.label || ''
    ];

  const assessmentTypeOptions =
    assessmentType?.id !== 15 ? assessmentType?.options : [];

  const [data, setData] = React.useState<AssessmentData>(
    initial || ({} as AssessmentData),
  );
  React.useEffect(() => {
    if (initial) {
      setData(initial);
    }
  }, [initial]);

  const onSave = React.useCallback(async () => {
    if (assertOnline()) {
      if (data) {
        await ops.editAssessment(assessmentId, data);
      }
      navigation.goBack();
    }
  }, [assertOnline, assessmentId, ops, navigation, data]);

  const onDelete = React.useCallback(async () => {
    if (assertOnline()) {
      await ops.deleteAssessment(assessmentId, data);
      showToast('Úspěšně', 'Hodnocení bylo smazáno', 'success', 3000);
      navigation.goBack();
    }
  }, [assertOnline, assessmentId, ops, navigation, data]);
  const isMaleGender = child?.gender === 'M';
  return (
    <>
      <CustomHeader
        dialog
        title={<Subheading style={styles.white}>Upravit hodnocení</Subheading>}
        menu={
          <View style={styles.menu}>
            <Appbar.Action
              icon="delete"
              color={theme.colors.primary}
              onPress={() => setDeleteDialogVisible(true)}
              style={styles.trashIcon}
            />
          </View>
        }
      />

      <BackgroundKeyboard>
        <Scroller>
          <DatePicker
            label="Datum hodnocení"
            date={
              data.date_of_assessment
                ? new Date(data.date_of_assessment)
                : new Date()
            }
            setDate={(date) => {
              if (date) {
                setData((x) => ({
                  ...x,
                  date_of_assessment: format(date, 'yyyy-MM-dd'),
                }));
              }
            }}
          />

          <View style={{ height: 10 }} />
          <FadeInFromTopAnimation delay={400} duration={400}>
            <Card
              style={{
                padding: 12,
                borderColor: isMaleGender ? colors.blue : colors.darkRed,
                borderWidth: 1,
              }}
              elevation={2}
            >
              <View style={{ gap: 5 }}>
                <View style={[styles.row, styles.between]}>
                  <Caption>
                    Hodnotil(a) {user?.first_name} {user?.last_name}
                  </Caption>
                  {CategoryIcon && <CategoryIcon style={styles.icon} />}
                </View>
                <Divider
                  style={{
                    backgroundColor: isMaleGender
                      ? colors.veryLightBlue
                      : colors.veryLightDarkRed,
                    marginVertical: 2,
                  }}
                />
                <View style={styles.row}>
                  <TouchableOpacity 
                    onPress={() =>
                      navigation.navigate('Child', {
                        childId: child?.id as number,
                      })
                    }
                    style={styles.childTouchable}
                    activeOpacity={0.7}
                  >
                    <ChildTypeGender
                      isGender={isMaleGender}
                      child={child}
                      isChevronRight
                    />
                  </TouchableOpacity>
                </View>
                <Divider
                  style={{
                    backgroundColor: isMaleGender
                      ? colors.veryLightBlue
                      : colors.veryLightDarkRed,
                    marginVertical: 2,
                  }}
                />
                <Subheading style={styles.desc}>
                  {task?.task_description}
                </Subheading>
                <AssessmentTypePicker
                  options={assessmentTypeOptions}
                  selected={data.option}
                  onSelect={(option) =>
                    setData((x) => ({ ...(x || {}), option }))
                  }
                />
                    <IconWrapper
                      delay={500}
                      iconSize={18}
                      color={isMaleGender ? colors.blue : colors.darkRed}
                      bgColor={isMaleGender ? colors.veryLightBlue : colors.veryLightDarkRed}
                      width={40}
                      height={40}
                      icon={'note-multiple-outline'}
                    >
                        {/* <View style={styles.inputContainer}> */}
                      <TextInput
                        value={data.note || ''}
                        onChangeText={(note) => setData((x) => ({ ...x, note }))}
                        label="Poznámka"
                        autoComplete="off"
                        multiline
                        numberOfLines={1}
                        style={[styles.whiteBg, styles.flex]}
                        autoFocus
                        right={data.note ? (
                          <TextInput.Icon 
                            icon="close-circle" 
                            onPress={() => setData((x) => ({ ...x, note: '' }))}
                            color={theme.colors.primary}
                          />
                        ) : null}
                      />
                    </IconWrapper>
              </View>
            </Card>
          </FadeInFromTopAnimation>

          <View style={{ minHeight: 10, flexGrow: 1 }} />
          <Button mode="contained" onPress={onSave}>
            Uložit
          </Button>
        </Scroller>
      </BackgroundKeyboard>
      <DeleteItemDialog
        visible={deleteDialogVisible}
        setDialogVisible={setDeleteDialogVisible}
        onDelete={onDelete}
        description={`Opravdu chcete smazat hodnocení "${task?.task_description}"?`}
      />
    </>
  );
});

const styles = StyleSheet.create({
  white: {
    color: 'white',
  },
  whiteBg: {
    backgroundColor: 'white',
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: 4,
    alignSelf: 'flex-end',
  },
  child: {
    fontSize: 17,
    marginLeft: 8,
  },
  review: {
    color: 'white',
    marginTop: 0,
  },
  desc: {
    padding: 8,
  },
  between: {
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex: {
    flex: 1,
  },

  
  assessmentTypeContainer: {
    flexDirection: 'row',
    marginVertical: 10,
  },
  assessmentType: {
    margin: 2,
    flex: 1,
    paddingHorizontal: 2,
    paddingVertical: 5,
    borderStyle: 'solid',
    borderWidth: 3,
    borderColor: 'transparent',
  },
  childTouchable: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    borderRadius: 8,
    padding: 4,
  },
  assessmentTypeSelected: {
    borderColor: theme.colors.primary,
  },
  assessmentTypeText: {
    flex: 1,
    textAlign: 'center',
    textAlignVertical: 'center',
  },

  menu: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    backgroundColor: 'white',
    borderRadius: 10000,
    marginRight: 7,
    marginBottom: 7,
  },
  trashIcon: {
    margin: 0,
  },

  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  trashIconNote: {
    position: 'absolute',
    right: 8,
    top: '50%',
    transform: [{ translateY: -10 }],
    margin: 0,
  },
});
