import React from 'react';
import { StyleSheet } from 'react-native';
import { Dialog } from 'react-native-paper';
import { ChildTaskPicker } from '../child/ChildTaskPicker';
import { ClassTaskPicker } from '../class/ClassTaskPicker';

import { DialogButtons } from './DialogButtons';
import { Components } from '../../server';
import { SubcategorySections } from '../../store';
import RollOptions from '../ui/RollOptions';

interface Props {
  category: Components.Schemas.Category | undefined;
  rollExtendedData: boolean;
  setRollExtendedData: React.Dispatch<React.SetStateAction<boolean>>;
  childIds: number[];
  categoryId: number;
  tempTaskIds: number[];
  setTempTaskIds: React.Dispatch<React.SetStateAction<number[]>>;
  classId: number;
  onSelectAllTasks?: () => void;
  categoryTasks: SubcategorySections;
  setTaskOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTaskIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const TaskDialogContent = React.memo(function ({
  category,
  rollExtendedData,
  setRollExtendedData,
  childIds,
  categoryId,
  tempTaskIds,
  setTempTaskIds,
  classId,
  onSelectAllTasks,
  categoryTasks,
  setTaskOpen,
  setTaskIds,
}: Props) {
  return (
    <>
      <RollOptions
        setShowData={setRollExtendedData}
        showData={rollExtendedData}
        name={category?.label}
      />

      <Dialog.ScrollArea style={styles.noPadding}>
        {childIds.length === 1 && category ? (
          <ChildTaskPicker
            showExtendedData={rollExtendedData}
            categoryId={categoryId}
            childId={childIds[0]!}
            selected={tempTaskIds}
            onSelect={setTempTaskIds}
            isHistoryButton={false}
          />
        ) : (
          <ClassTaskPicker
            showExtendedData={rollExtendedData}
            categoryId={categoryId}
            classId={classId}
            selected={tempTaskIds}
            onSelect={setTempTaskIds}
            isHistoryButton={false}
          />
        )}

        <DialogButtons
          onSetTempIds={setTempTaskIds}
          tempIds={tempTaskIds}
          setOpen={setTaskOpen}
          setIds={setTaskIds}
          data={categoryTasks}
          onSelectAllIds={onSelectAllTasks}
        />
      </Dialog.ScrollArea>
    </>
  );
});

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 7,
    marginRight: 5,
  },
  noPadding: {
    paddingHorizontal: 0,
  },
});
