import React from 'react';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {  StyleSheet} from 'react-native';
import { colors } from '../../theme';
import { IconButton } from 'react-native-paper';
type Props = {
    onPress: () => void;
    onPressStart: () => void;
    borderColor?: string;
  };

export const NoteButton = React.memo(({ onPress, onPressStart, borderColor }: Props) => {
    return (
      <IconButton
        size={21}
        onPressIn={() => onPressStart()}
        onPress={onPress}
        style={styles.noteButtonContainer}
        icon={() => (
            <Icon
              name={'note-multiple-outline'}
              size={16}
              color={borderColor}
            />
          )}
      >
      </IconButton>
    );
  });

  const styles = StyleSheet.create({
    noteButtonContainer: {
      position: 'absolute',
      right: -14,
      top: -6,
      backgroundColor: colors.white,
      borderRadius: 20,
      zIndex: 20,
      elevation: 3, 
      shadowColor: '#000', 
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.2,
      shadowRadius: 0.84,

    },
  });