import React from 'react';
import { Dimensions, FlatList, StyleSheet, View } from 'react-native';
import {
  Button,
  Caption,
  Card,
  Divider,
  IconButton,
  Portal,
  Subheading,
  Text,
} from 'react-native-paper';
import { Components } from '../../server';
import { CustomCheckbox } from '../ui/CustomCheckbox';
import { colors, theme } from '../../theme';
import { AssessmentTypeType, TaskType } from '../../store';
import { DifficultyIcon } from '../ui/DifficultyIcon';
import { setBgColor, setBorderColor } from '../../lib/set-color';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';
import { format } from 'date-fns';
import { CustomDialog } from '../ui/CustomDialog';
import IconWrapper from '../ui/IconWrapper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import FadeInFromLeftOrRight from '../animations/FadeInFromLeftOrRightAnimation';
import { truncateText } from '../../lib/truncate';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';
import { isWeb } from '../../lib/is-web';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { RootStackParamList } from '../../lib/navigation';
import { NoteButton } from './NoteButton';

export const TaskListItem: React.FC<{
  item: TaskType;
  assessments?: Components.Schemas.Assessment[];
  assessmentType: AssessmentTypeType;
  checked?: boolean;
  onPress: (id: number) => void;
  onCheck: (id: number) => void;
  childId?: number;
  classId?: number;
  isHistoryButton?: boolean;
}> = React.memo(function TaskListItem(props) {
  const { item, assessments, assessmentType, checked, onCheck, onPress, childId, classId, isHistoryButton } =
    props;
  const [id, ...rest] = (item.codename || '').split(' ');
  const [notesOpen, setNotesOpen] = React.useState(false);
  const [dialogNotes, setDialogNotes] = React.useState<
    Components.Schemas.Assessment[]
  >([]);
  const [displayedNotes, setDisplayedNotes] = React.useState<
    Components.Schemas.Assessment[]
  >([]);
  const [option, setOption] = React.useState<{
    id: number;
    label: string;
  }>();
  const [isNoteButtonPressed, setIsNoteButtonPressed] = React.useState(false);
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { isDesktop } = useDesktopStyles();

  const handleOptionPress = React.useCallback((optionId: number) => {
    if (childId) {
      navigation.navigate('CreateAssessment', {
        classId: classId || 0,
        childIds: [childId],
        taskIds: [item.id],
        categoryId: item.subcategory ? undefined : item.id,
        selectedOption: optionId, 
      });
    }
  }, [childId, item.id, navigation]);

  const latestOption = React.useMemo(() => {
    if (!assessments || assessments.length <= 0) {
      return null;
    }
    return assessments.reduce((prev, curr) => {
      return prev.date_of_assessment < curr.date_of_assessment ? curr : prev;
    }).option;
  }, [assessments]);

  const _onPress = React.useCallback(() => {
    onPress(item.id);
  }, [onPress, item]);

  const _onCheck = React.useCallback(() => {
    onCheck(item.id);
  }, [onCheck, item]);

  React.useEffect(() => {
    setDisplayedNotes(dialogNotes.slice(0, 6));
  }, [dialogNotes]);

  // Hack - to make the dialog fixed on web and not scroll with the page, its just for this component
  React.useEffect(() => {
    if (isWeb) {
      document.body.style.overflow = notesOpen ? 'hidden' : 'auto';
      document.body.style.position = notesOpen ? 'fixed' : 'relative';
    }
    return () => {
      if (isWeb) {
        document.body.style.overflow = 'auto';
      }
    };
  }, [notesOpen]);

  return (
    <>
      <View style={styles.row}>
        {item.parent_task && (
          <View style={styles.difficultyIcon}>
            <DifficultyIcon difficulty={item.difficulty} />
          </View>
        )}
        <Card
          style={[
            styles.flex,
            {
              marginVertical: 6,
              backgroundColor: checked ? colors.veryLightOrange : 'white',
              borderColor: checked
                ? colors.lightOrange
                : colors.veryLightOrange,
              borderWidth: 1,
            },
          ]}
          elevation={2}
          nativeID='task-list-item'
          onPress={_onCheck}
        >
          <TouchableOpacity style={{flex: 1}}>
          <Card.Content style={[styles.row, { paddingTop: 12, paddingBottom: 0 }]}>
            <View style={[styles.flex]}>
              <View style={styles.headerRow}>
                <View style={styles.titleSection}>
                  {item.codename ? (
                    <Text style={styles.number}>
                      {(id ? `${id}. ` : '') + rest.join(' ')}
                    </Text>
                  ) : (
                    <Subheading style={styles.subheading}>
                      {item?.task_description}
                    </Subheading>
                  )}
                </View>
                
                {item.ageString && (
                  <Text style={styles.age}>
                    Věk:{'\xa0'}
                    {item.ageString}
                  </Text>
                )}
                
                {isHistoryButton && <IconButton
                  icon="history"
                  size={22}
                  iconColor={colors.darkRed}
                  nativeID='history_button'
                  onPress={_onPress}
                  style={styles.historyButton}
                />}
                  <CustomCheckbox checked={checked}  onPress={_onCheck}  />
              </View>
              
              <View style={{ paddingBottom: 4, paddingTop: 0 }}>
                {item.codename ? (
                  <Subheading style={styles.subheading}>
                    {item?.task_description}
                  </Subheading>
                ) : null}
              </View>
            </View>
          </Card.Content>
          </TouchableOpacity>

          {assessmentType && assessments && (
            <Card.Content
              style={[
                styles.assessmentTypes,
                {
                  paddingHorizontal: isDesktop ? 8 : 2,
                  paddingTop: 4,
                  gap: isDesktop ? 4 : 0,
                },
              ]}
            >
              {assessmentType.options?.map((option, i) => {
                const currentAssessments = assessments.filter(
                  (x) => x.option === option.id,
                );

                const filteredNotes = currentAssessments.filter(
                  (assessment) =>
                    assessment.note && assessment.note.trim().length > 0,
                );
                const sortedFilteredNotes = filteredNotes.sort(
                  (a, b) =>
                    new Date(b.date_of_assessment).getTime() -
                    new Date(a.date_of_assessment).getTime(),
                );
                const isLatestSelected = latestOption === option.id;

                const borderColor = isLatestSelected
                  ? setBorderColor(option)
                  : colors.lightGrey2;

                const backgroundColor = isLatestSelected
                  ? setBgColor(option)
                  : '#fff';

                const marginLeft = i === 0 ? undefined : 2;

                return (
                  <BubbleScaleUpAnimation
                    flexOne
                    key={option.id}
                    delay={(i + 1) * 250}
                  >
                    <View
                      key={option.id}
                      style={{
                        flex: 1,
                        paddingHorizontal: 0,
                        flexDirection: 'column',
                        position: 'relative',
                      }}
                    >
                     <TouchableOpacity
                          nativeID='task-list-item-option'
                          onPress={() => {
                            if (!isNoteButtonPressed) { 
                              handleOptionPress(option.id);
                            }
                            setIsNoteButtonPressed(false);
                          }}
                    
                          style={[
                            styles.assessmentTypeOption,
                            {
                              borderColor,
                              backgroundColor,
                              borderRadius: 10,
                              padding: isDesktop ? 6 : 4,
                            
                            },
                          ]}
                          // className={isWeb ? "assessment-option" : undefined}
                        >
                        <View style={{ flex: 1 }}>
                          <View
                            style={{
                              gap: 2,
                            }}
                          >
                            
                              <Text
                                style={[
                                  styles.assessmentTypeLabel,
                                  {
                                    fontSize: 14,
                                    textAlign: 'center',
                                  },
                                ]}
                              >
                                {option.label}
                              </Text>
                            
                            <View
                              style={{
                                gap: 8,
                              }}
                            >
                              {sortedFilteredNotes
                                ?.slice(0, 1)
                                .map((assessment) => {
                                  const { truncatedText, isTruncated } =
                                    truncateText(assessment.note);
                                  return (
                                    <View key={assessment.id}>
                                      <Text
                                        style={{
                                          color: borderColor,
                                          fontSize: 12,
                                          marginVertical: 1,
                                        }}
                                      >
                                        {format(
                                          new Date(
                                            assessment.date_of_assessment,
                                          ),
                                          'MM/yy',
                                        )}{' '}
                                        {truncatedText}
                                      </Text>
                                      {(isTruncated ||
                                        sortedFilteredNotes.length >= 2) && (
                                        <NoteButton
                                          borderColor={borderColor}
                                          onPressStart={() => setIsNoteButtonPressed(true)}
                                          onPress={() => {
                                            setDialogNotes(filteredNotes);
                                            setOption(option);
                                            setNotesOpen(true);
                                          }}
                                        />
                                      )}
                                    </View>
                                  );
                                })}
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>

                      <View
                        style={{
                          alignSelf: 'flex-end',
                          justifyContent: 'center',
                          width: '100%',
                          minHeight: 20,
                          paddingHorizontal: 4,
                        }}
                      />
                    </View>
                  </BubbleScaleUpAnimation>
                );
              })}
            </Card.Content>
          )}
        </Card>
      </View>
      <Portal>
        <CustomDialog
          visible={notesOpen}
          onDismiss={() => setNotesOpen(false)}
          style={{
            maxHeight: 0.75 * Dimensions.get('window').height,
          }}
        >
          <View style={{ flex: 1 }}>
            <FadeInFromLeftOrRight delay={50} direction="left">
              <CustomDialog.Title>Poznámky</CustomDialog.Title>
              <CustomDialog.Content>
                <Subheading>
                  {(id ? `${id}. ` : '') +
                    rest.join(' ') +
                    ' - ' +
                    item?.task_description}
                </Subheading>
                <Caption
                  style={{
                    fontSize: 13,
                  }}
                >
                  {option?.label}
                </Caption>
              </CustomDialog.Content>
            </FadeInFromLeftOrRight>

            <Divider style={{ marginVertical: 6, marginBottom: 12 }} />
            <FlatList
              data={displayedNotes}
              renderItem={({ item, index }) => (
                <View
                  key={item.id}
                  style={{
                    marginTop: 4,
                    flexWrap: 'wrap',
                    marginVertical: 10,
                    maxWidth: 0.75 * Dimensions.get('window').width,
                    paddingHorizontal: 10,
                  }}
                >
                  {item?.note ? (
                    <IconWrapper
                      delay={index * 100}
                      iconSize={18}
                      color={setBorderColor(option)}
                      bgColor={setBgColor(option)}
                      width={40}
                      height={40}
                      icon={'note-multiple-outline'}
                    >
                      <FadeInFromLeftOrRight
                        delay={index * 75}
                        direction="left"
                      >
                        <View>
                          <Caption>
                            {format(
                              new Date(item.date_of_assessment),
                              'dd.MM.yyyy',
                            )}
                          </Caption>
                          <Text
                            key={item.id}
                            style={{
                              maxWidth: 0.9 * Dimensions.get('window').width,
                              fontSize: 15,
                            }}
                          >
                            {item.note}
                          </Text>
                        </View>
                      </FadeInFromLeftOrRight>
                    </IconWrapper>
                  ) : null}
                </View>
              )}
              keyExtractor={(item) => item.id.toString()}
              ListFooterComponent={() =>
                dialogNotes.length > 6 &&
                displayedNotes.length < dialogNotes.length ? (
                  <Button
                    onPress={() =>
                      setDisplayedNotes(
                        dialogNotes.slice(0, displayedNotes.length + 6),
                      )
                    }
                  >
                    Načíst více
                  </Button>
                ) : null
              }
              scrollEnabled={true}
            />
            <Divider style={{ marginVertical: 4 }} />

            <Button
              style={{
                width: '50%',
                alignSelf: 'center',
                marginVertical: 12,
                borderColor: theme.colors.primary,
              }}
              mode="outlined"
              onPress={() => {
                setNotesOpen(false);
              }}
            >
              Zavřít
            </Button>
          </View>
        </CustomDialog>
      </Portal>
    </>
  );
});

const styles = StyleSheet.create({
  flex: {
    flex: 1,
    ...(isWeb ? {
      cursor: 'pointer',
    } : {}),
  },

  row: {
    flexDirection: 'row', 
  },

  headerRow: {  
    flexDirection: 'row',
    alignItems: 'flex-start',
  },

  titleSection: { 
    flex: 1,
  },
  number: {
    fontWeight: 'bold',
    color: colors.grey,
    fontSize: 14,
    padding: 1,
  },
  subheading: {
    color: colors.text,
    paddingLeft: 3,
    fontSize: 14,
    maxWidth:
      Dimensions.get('window').width > 550
        ? Dimensions.get('window').width
        : Dimensions.get('window').width - 110,
  },
  age: {
    color: colors.grey,
    fontWeight: '500',
    fontSize: 13,
    marginLeft: 'auto',
    marginRight: 8,
  },

  historyButton: {
    marginLeft: 'auto',
    marginTop: -6,
  },

  checkbox: {
    marginLeft: 4,
    marginTop: 2,
    alignItems: 'flex-end',
  },
  assessmentTypeLabel: {
    marginBottom: 4,
    color: 'rgba(0,0,0,0.6)',
  },
  assessmentTypeOption: {
    borderWidth: 3,
    flex: 1,
    ...(isWeb ? {
      cursor: 'pointer',
      WebkitTransition: 'all 0.2s ease-in-out',
      transition: 'all 0.2s ease-in-out',
    } : {}),
  },
  assessmentTypes: {
    flexDirection: 'row',
    paddingBottom: 0,
  },
  difficultyIcon: {
    width: 35,
    marginLeft: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
