import React from 'react';
import { useRecoilValue } from 'recoil';
import { classesState, schoolState } from '../store';
import { Linking, View } from 'react-native';
import { Headline, Subheading, Switch, Text } from 'react-native-paper';
import { Button } from '../components/ui/Button';
import { Background } from '../components/ui/Background';
import { useCurrentUser } from '../utils';
import { Scroller } from '../components/ui/Scroller';
import IconWrapper from '../components/ui/IconWrapper';
import { theme } from '../theme';
import { isWeb } from '../lib/is-web';
import { useTourControl } from '../components/tour-guide/TourGuideControlProvider';
import { showToast } from '../lib/toast';

export const ProfileScreen = React.memo(function ProfileScreen({
  navigation,
}: any) {
  const school = useRecoilValue(schoolState);
  const classes = useRecoilValue(classesState);
  const user = useCurrentUser();
  const { isTourEnabled, toggleTourEnabled, resetAllTours} = useTourControl();
  
  const handleReset = async () => {
    try {
      await resetAllTours();
      await toggleTourEnabled((newState) => {
        if (newState) {
          showToast(
            'Průvodce aplikací byl zapnut',
            'Budete přesměrováni na domovskou stránku',
            'success',
            3000,
          );
          navigation.navigate('ClassHome', { classId: firstClass ?? '' });
        } else {
          showToast(
            'Průvodce aplikací byl vypnut',
            'Nápověda se nezobrazí při příští návštěvě obrazovky',
            'success',
            3000,
          );
        }
      });

    } catch (error) {
      showToast(
        'Error',
        'Chyba při resetování průvodce',
        'error',
        3000,
      );
    } 
  };

  const firstClass = Object.values(classes)[0]?.id;
  const changePassword = React.useCallback(
    () =>
      Linking.openURL(
        'https://klokanovaapka.luzanky.cz/admin/password_change/',
      ),
    [],
  );
  const changeUser = React.useCallback(
    () =>
      Linking.openURL(
        `https://klokanovaapka.luzanky.cz/admin/record_sheet/user/${user?.id}/change/`,
      ),
    [user],
  );

  return (
    <Background>
      <Scroller>
        <View style={{ marginVertical: 10, gap: 10 }}>
          <IconWrapper icon="account" width={40} height={40}>
            <Headline>
              {user?.first_name} {user?.last_name}
            </Headline>
          </IconWrapper>
          <IconWrapper icon="email" width={40} height={40}>
            <Subheading>{user?.email}</Subheading>
          </IconWrapper>
          <IconWrapper icon={'school'} width={40} height={40}>
            <Text>{school?.name}</Text>
          </IconWrapper>
        </View>
        <View style={{ marginVertical: 10 }}>
          <IconWrapper icon="help-circle-outline" width={40} height={40}>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
              <View>
                <Subheading>Průvodce aplikací</Subheading>
                <Text>Zobrazit průvodce pro stránky</Text>
                <Text>Po zapnutí průvodce budete přesměrováni na domovskou stránku</Text>
                <Text>Pro resetování průvodce vypněte a zapněte přepínač</Text>
                
              </View>
              <Switch
                value={isTourEnabled}
                onValueChange={handleReset}
                color={theme.colors.primary}
              />
            </View>
          </IconWrapper>
        </View>

        {Object.values(classes).length < 1 && (
          <View style={{ marginVertical: 10 }}>
            <Subheading>
              Správce vaší školy vám zatím nepřiřadil žádnou třídu.
            </Subheading>
            <Text>
              Jakmile budete mít přiřazenou nějakou třídu, odhlašte se a
              přihlašte se znovu.
            </Text>
            <Text>
              Můžete si mezitím také vyzkoušet funkce aplikace kliknutím na
              Přihlásit do demoverze na přihlašovací obrazovce.
            </Text>
          </View>
        )}

        <Button mode="contained" onPress={changePassword}>
          Změnit heslo
        </Button>
        <Button mode="contained" onPress={changeUser}>
          Upravit uživatelské údaje
        </Button>
        {isWeb && (
          <Button
            style={{ width: '100%' }}
            uppercase
            labelStyle={{ color: theme.colors.primary }}
            mode="outlined"
            onPress={() =>
              navigation.navigate('ClassHome', { classId: firstClass })
            }
          >
            Přesměrovat na domovskou stránku
          </Button>
        )}
      </Scroller>
    </Background>
  );
});
