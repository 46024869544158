import React from 'react';
import { FlatList, Pressable, StyleSheet, View } from 'react-native';
import { Card, Text, TouchableRipple } from 'react-native-paper';
import { useRecoilValue } from 'recoil';
import { Components } from '../../server';
import { categoriesState, categoryOrderState } from '../../store';
import { icons } from '../icons';
import { colors } from '../../theme';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';

interface CategoryPickerProps {
  onSelect: (catId: number) => void;
  activeId?: number;
}

export const CategoryPicker = ({ onSelect, activeId }: CategoryPickerProps) => {
  const categories = useRecoilValue(categoriesState);
  const categoryOrder = useRecoilValue(categoryOrderState);

  const { isDesktop } = useDesktopStyles();

  const renderItem = ({
    item,
    index,
  }: {
    item: Components.Schemas.Category;
    index: number;
  }) => {
    const Icon = icons[item.label];
    return (
      <BubbleScaleUpAnimation flexOne delay={(index + 1) * 100}>
        <Pressable
          nativeID={index === 0 ? 'category_card' : undefined}
          onPress={() => onSelect(item.id)}
          style={({ pressed }) => [
            {
              backgroundColor: pressed
                ? colors.veryLightDarkRed
                : 'transparent',
            },
            { flex: 1 },
          ]}
        >
          <Card
            elevation={2}
            key={item.id}
            style={[
              styles.box,
              {
                backgroundColor:
                  activeId === item.id ? colors.veryLightOrange : 'white',
              },
              { paddingVertical: isDesktop ? 24 : 12 },
            ]}
          >
            <View style={styles.innerBox}>
              {Icon && <Icon style={styles.icon} />}
              <Text style={styles.label}>
                {item.label.replace(/ a /, ' a\xa0').replace('-', ', ')}
              </Text>
            </View>
          </Card>
        </Pressable>
      </BubbleScaleUpAnimation>
    );
  };

  return (
    <FlatList
      nativeID="category_list"
      style={{ margin: 5, padding: 5 }}
      data={categoryOrder.map((id) => categories[id]!)}
      keyExtractor={(item) => item.id.toString()}
      numColumns={2}
      renderItem={renderItem}
    />
  );
};

const styles = StyleSheet.create({
  box: {
    flex: 1,
    margin: 8,
    padding: 20,
  },
  innerBox: {
    gap: 4,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  icon: {
    width: 55,
    height: 55,
    marginHorizontal: 'auto',
  },
  label: {
    alignSelf: 'stretch',
    textAlign: 'center',
    marginTop: 5,
    fontSize: 13,
  },
});
