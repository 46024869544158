import React from 'react';
import { useIsFocused } from '@react-navigation/native';
import { ClassCategoryProps } from '../lib/navigation';
import { Background } from '../components/ui/Background';
import { ClassTaskPicker } from '../components/class/ClassTaskPicker';
import { useMultiFABScroll } from '../components/multifab/MultiFABContext';
import { IconButton, Portal } from 'react-native-paper';
import { MultiFAB } from '../components/multifab/MultiFAB';
import RollOptions from '../components/ui/RollOptions';
import { categoryState } from '../store';
import { useRecoilValue } from 'recoil';
import { colors } from '../theme';
import { showToast } from '../lib/toast';
import { StyleSheet } from 'react-native';

export const ClassCategoryScreen = React.memo(function ClassCategoryScreen({
  route,
  navigation,
}: ClassCategoryProps) {
  const { classId, categoryId } = route.params;
  const isFocused = useIsFocused();
  const { setStatus } = useMultiFABScroll();
  const [selected, onSelect] = React.useState<number[]>([]);
  const category = useRecoilValue(categoryState(categoryId));
  const [showExtendedData, setShowExtendedData] = React.useState(false);
  const onPress = React.useCallback(
    (id: number) => {
      navigation.push('ClassTask', { classId, taskId: id });
    },
    [navigation, classId],
  );

  const onDeleteAllSelected = React.useCallback(() => {
    onSelect([]);
    showToast(
      'Vybrané úlohy byly odebrány',
      'Můžete vybrat nové úlohy',
      'success',
      3000,
    );
  }, [onSelect]);

  React.useEffect(() => {
    if (isFocused) {
      if (selected.length > 0) {
        setStatus({
          multi: true,
          initial: { classId, categoryId, taskIds: selected },
        });
      } else {
        setStatus({ initial: { classId, categoryId } });
      }
    }
  }, [isFocused, setStatus, classId, categoryId, selected]);

  return (
    <Background>
      <RollOptions
        setShowData={setShowExtendedData}
        showData={showExtendedData}
        name={category?.label}
        style={{ marginTop: 8 }}
      />
      <ClassTaskPicker
        {...{
          classId,
          categoryId,
          selected,
          onPress,
          onSelect,
          showExtendedData,
          isHistoryButton: true,
        }}
      />
      {selected.length >= 2 && (
        <IconButton
          style={styles.deleteButton}
          icon="delete"
          iconColor="white"
          size={24}
          animated
          android_ripple={{ color: 'rgba(0, 0, 0, .32)' }}
          rippleColor={colors.veryLightRed}
          onPress={onDeleteAllSelected}
        />
      )}
      <Portal>
        <MultiFAB visible={isFocused} />
      </Portal>
    </Background>
  );
});

const styles = StyleSheet.create({
  deleteButton: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 50,
    width: 56,
    height: 56,
    backgroundColor: colors.red,
    color: colors.white,
    margin: 16,
    borderRadius: 50,
  },
});
