import React from 'react';
import { CategoryPicker } from '../category/CategoryPicker';
import { ClassCategorySelectProps } from '../../lib/navigation';
import { ClassIDContext } from '../../lib/contexts';
import { useIsFocused } from '@react-navigation/native';
import { useMultiFABScroll } from '../multifab/MultiFABContext';
import { Background } from '../ui/Background';

export const  ClassCategorySelect = ({
  navigation,
}: ClassCategorySelectProps) => {
  const classId = React.useContext(ClassIDContext);
  const isFocused = useIsFocused();
  const { setStatus } = useMultiFABScroll();

  React.useEffect(() => {
    if (isFocused) {
      setStatus({ initial: { classId } });
    }
  }, [isFocused, setStatus, classId]);

  return (
    <Background>
      <CategoryPicker
        onSelect={(categoryId) =>
          navigation.navigate('ClassCategory', { classId, categoryId })
        }
      />
    </Background>
  );
};
