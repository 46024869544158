import React, { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface TourControlContextType {
    isTourEnabled: boolean;
    toggleTourEnabled: (callback?: (newState: boolean) => void) => Promise<void>;
    resetTourForScreen: (screenName: string) => Promise<void>;
    resetAllTours: () => Promise<void>;
  }
  
  export const TourControlContext = React.createContext<TourControlContextType>({
    isTourEnabled: false,
    toggleTourEnabled: async () => { },
    resetTourForScreen: async () => {},
    resetAllTours: async () => {},
  });

export const TourControlProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isTourEnabled, setIsTourEnabled] = useState(false);
  
    useEffect(() => {
      initializeTourSetting();
  }, []);

  const initializeTourSetting = async () => {
      try {
          const tourSetting = await AsyncStorage.getItem('tour_enabled');
          if (tourSetting === null) {
              await AsyncStorage.setItem('tour_enabled', 'false');
              setIsTourEnabled(false);
          } else {
              setIsTourEnabled(tourSetting === 'true');
          }
      } catch (error) {
          console.error('Error initializing tour setting:', error);
      }
  };

  const toggleTourEnabled = async (callback?: (newState: boolean) => void) => {
    try {
      const newState = !isTourEnabled;
      await AsyncStorage.setItem('tour_enabled', String(newState));
      setIsTourEnabled(newState);
  
      if (newState) {
        const keys = await AsyncStorage.getAllKeys();
        const tourKeys = keys.filter(key => key.startsWith('tour_completed_'));
        await AsyncStorage.multiRemove(tourKeys);
      }
  
      if (callback) {
        callback(newState); 
      }
    } catch (error) {
      console.error('Error toggling tour state:', error);
    }
  };

  const resetTourForScreen = async (screenName: string) => {
    try {
        await AsyncStorage.removeItem(`tour_completed_${screenName}`);
        const tourEnabled = await AsyncStorage.getItem('tour_enabled');
        if (tourEnabled === 'true') {
            window.dispatchEvent(new Event('storage'));
        }
    } catch (error) {
        console.error('Error resetting tour for screen:', error);
    }
};


const resetAllTours = async () => {
  try {
    const keys = await AsyncStorage.getAllKeys();
    const tourKeys = keys.filter(key => key.startsWith('tour_completed_'));
    await AsyncStorage.multiRemove(tourKeys);
    await AsyncStorage.setItem('tour_enabled', 'true');
    setIsTourEnabled(true);
  } catch (error) {
    console.error('Error resetting all tours:', error);
  }
};
  
    return (
      <TourControlContext.Provider 
        value={{ 
          isTourEnabled, 
          toggleTourEnabled, 
          resetTourForScreen, 
          resetAllTours,
        }}
      >
        {children}
      </TourControlContext.Provider>
    );
  };
  
  export const useTourControl = () => {
    const context = React.useContext(TourControlContext);
    if (!context) {
      throw new Error('useTourControl must be used within a TourControlProvider');
    }
    return context;
  };
  