import { useIsFocused } from '@react-navigation/native';
import React from 'react';
import { FlatList, View, StyleSheet } from 'react-native';
import { Caption, Card, IconButton, Text } from 'react-native-paper';
import { ChildIDContext } from '../../lib/contexts';
import { ChildNotesProps } from '../../lib/navigation';
import { useRecoilValue } from 'recoil';
import { childNotesState, childState, usersState } from '../../store';
import { useMultiFABScroll } from '../multifab/MultiFABContext';
import { Components } from '../../server';
import { RefreshUserData } from '../RefreshUserData';
import { Background } from '../ui/Background';
import ChildTypeGender from './ChildTypeGender';
import FadeInFromLeftOrRight from '../animations/FadeInFromLeftOrRightAnimation';
import Notes from '../Notes';
import { colors } from '../../theme';

export const ChildNotes = ({ navigation }: ChildNotesProps) => {
  const isFocused = useIsFocused();
  const childId = React.useContext(ChildIDContext);
  const child = useRecoilValue(childState(childId))!;
  const notes = useRecoilValue(childNotesState(childId));
  const users = useRecoilValue(usersState);
  const { setStatus } = useMultiFABScroll();

  const isMaleGender = child?.gender === 'M';

  React.useEffect(() => {
    if (isFocused) {
      setStatus({
        initial: { classId: child?.classroom, childIds: [childId] },
      });
    }
  }, [isFocused, setStatus, childId, child?.classroom]);

  const renderItem = ({
    item,
    index,
  }: {
    item: Components.Schemas.ChildNote;
    index: number;
  }) => {
    const user = users[item.created_by || 0];
    return (
      <Notes
        item={item}
        index={index}
        navigate={() => {
          navigation.navigate('EditChildNote', { childId, noteId: item.id });
        }}
        user={user}
      />
    );
  };

  return (
    <Background>
      {notes.length <= 0 && (
        <View
          style={{ margin: 30, justifyContent: 'center', alignItems: 'center' }}
        >
          <Caption style={{
            marginVertical: 8,
            fontWeight: 'bold',
            fontSize: 18,
            color: colors.darkRed,
          }}>Přidejte poznámky kliknutím na {'"+"'}</Caption>
        </View>
      )}

      <FlatList
        contentContainerStyle={{ padding: 10 }}
        data={notes}
        ListHeaderComponent={
          <FadeInFromLeftOrRight delay={50}>
            <View style={{ height: 10 }} />
            <ChildTypeGender
              child={child}
              isGender={isMaleGender}
              fontSize={18}
            />
            <View style={{ height: 10 }} />
          </FadeInFromLeftOrRight>
        }
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderItem}
        refreshControl={<RefreshUserData />}
      />
    </Background>
  );
};
