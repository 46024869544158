import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { List } from 'react-native-paper';
import { useRecoilValue } from 'recoil';
import { ageRelevantCategoryState, SubcategorySections } from '../../store';
import { useMultiFABScroll } from '../multifab/MultiFABContext';
import { TaskPicker, TaskPickerProps } from '../task/TaskPicker';
import { RefreshUserData } from '../RefreshUserData';
import { SubcategoryPicker } from '../category/SubcategoryPicker';
import { ShowExtendedTasks } from '../task/ShowExtendedTasks';

import { FadeInFromTopAnimation } from '../animations/FadeInFromTopAnimation';


const filterSection = (
  section: SubcategorySections,
  subcategoryId: number,
  showSubtasks: boolean,
) => {
  const basis = subcategoryId
    ? section.filter((x) => x.id === subcategoryId)
    : section;
  return showSubtasks
    ? basis
    : basis.map((x) => ({ ...x, data: x.data.filter((x) => !x.parent_task) }));
};

export const ChildTaskPicker: React.FC<
  {
    categoryId: number;
    subcategoryId?: number;
    childId: number;
    classId?: number;
    showExtendedData: boolean;
    isHistoryButton?: boolean;
  } & Pick<TaskPickerProps, 'assessments' | 'onPress' | 'onSelect' | 'selected'>
> = ({
  assessments,
  onPress,
  categoryId,
  subcategoryId: subcategoryIdParams,
  childId,
  classId,
  selected,
  onSelect,
  showExtendedData,
  isHistoryButton,
}) => {
  const { handleScroll } = useMultiFABScroll();
  const rawSections = useRecoilValue(
    ageRelevantCategoryState({ categoryId, childId }),
  );

  const [subcategoryId, setSubcategoryId] = React.useState<number>(
    subcategoryIdParams ?? 0,
  );
  const [showSubtasks, setShowSubtasks] = React.useState(false);
  const scrollViewRef = React.useRef<ScrollView>(null);

  const sections = React.useMemo(
    () => ({
      forYounger: filterSection(
        rawSections.forYounger,
        subcategoryId,
        showSubtasks,
      ),
      appropriate: filterSection(
        rawSections.appropriate,
        subcategoryId,
        showSubtasks,
      ),
      forOlder: filterSection(
        rawSections.forOlder,
        subcategoryId,
        showSubtasks,
      ),
    }),
    [rawSections, subcategoryId, showSubtasks],
  );

  const counts = {
    forYounger: sections.forYounger.reduce((n, x) => n + x.data.length, 0),
    appropriate: sections.appropriate.reduce((n, x) => n + x.data.length, 0),
    forOlder: sections.forOlder.reduce((n, x) => n + x.data.length, 0),
  };

  const [forYounger, setForYounger] = React.useState(false);
  const [appropriate, setAppropriate] = React.useState(true);
  const [forOlder, setForOlder] = React.useState(false);

  return (
    <>
      {showExtendedData && (
        <FadeInFromTopAnimation>
          <SubcategoryPicker
            {...{ categoryId, subcategoryId, setSubcategoryId }}
          />
          <ShowExtendedTasks
            checked={showSubtasks}
            onToggle={() => setShowSubtasks((x) => !x)}
          />
        </FadeInFromTopAnimation>
      )}

      <ScrollView
        nativeID='child_task_picker'
        ref={scrollViewRef}
        onScroll={handleScroll}
        scrollEventThrottle={1}
        refreshControl={<RefreshUserData />}
      >
        <FadeInFromTopAnimation delay={300}>
          <List.Accordion
            title={`Úkoly pro mladší (${counts.forYounger})`}
            style={styles.whiteBg}
            expanded={forYounger}
            onPress={() => setForYounger((x) => !x)}
          >
            <TaskPicker
              {...{ assessments, selected, onSelect, onPress, childId, classId, isHistoryButton }}
              data={sections.forYounger}
            />
          </List.Accordion>
        </FadeInFromTopAnimation>

        <FadeInFromTopAnimation delay={400}>
          <List.Accordion
            title={`Úkoly odpovídající věku (${counts.appropriate})`}
            style={styles.whiteBg}
            expanded={appropriate}
            onPress={() => setAppropriate((x) => !x)}
          >
            <TaskPicker
              {...{ assessments, selected, onSelect, onPress, childId, classId, isHistoryButton }}
              data={sections.appropriate}
            />
          </List.Accordion>
        </FadeInFromTopAnimation>

        <FadeInFromTopAnimation delay={500}>
          <List.Accordion
            title={`Úkoly pro starší (${counts.forOlder})`}
            style={styles.whiteBg}
            expanded={forOlder}
            onPress={() => setForOlder((x) => !x)}
          >
            <TaskPicker
              {...{ assessments, selected, onSelect, onPress, childId, classId, isHistoryButton }}
              data={sections.forOlder}
            />
          </List.Accordion>
        </FadeInFromTopAnimation>
        <View style={{ height: 150 }} />
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  whiteBg: {
    backgroundColor: 'white',
    marginVertical: 6,
    paddingHorizontal: 20,
  },
});
