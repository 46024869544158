import React from 'react';
import { useNavigationState } from '@react-navigation/native';
import TourGuide from './TourGuide';
import { useTourControl } from './TourGuideControlProvider';
import { TOUR_STEPS } from './TourGuideSteps';
import { View } from 'react-native';

type TourScreen = keyof typeof TOUR_STEPS;

const SCREEN_TO_TOUR_MAP: Record<string, TourScreen> = {
  // Root level
  'ClassHome': 'CLASS_HOME_SCREEN',
  'Class': 'CLASS_CHILDREN_SCREEN',
  'EditClassroomNote': 'EDIT_CLASSROOM_NOTE',
  
  // Class tabs
  'ClassChildren': 'CLASS_CHILDREN_SCREEN',
  'ClassCategorySelect': 'CLASS_CATEGORY_SCREEN',
//   'ClassStats': 'CLASS_STATS_SCREEN',
  'ClassNotes': 'CLASS_NOTES_SCREEN',
  
  // Child tabs
  'ChildOverview': 'CHILD_OVERVIEW_SCREEN',
  'ChildCategory': 'CHILD_CATEGORY_SELECT_SCREEN',
  'ChildNotes': 'CHILD_NOTES_SCREEN',
};

interface GlobalTourWrapperProps {
  children: React.ReactNode;
}

export const GlobalTourWrapper: React.FC<GlobalTourWrapperProps> = ({ children }) => {
  const { isTourEnabled } = useTourControl();
  const navigationState = useNavigationState(state => state);
  const [currentScreen, setCurrentScreen] = React.useState<TourScreen | ''>('');

  const getActiveRouteName = (state: any): string => {
    if (!state?.routes?.length) return '';

    const route = state.routes[state.index || (state.routes.length - 1)];
    
    
    if (route.state) {
      return getActiveRouteName(route.state);
    }

    if (route.name === 'Child') {
      const childState = route.params?.screen || 'ChildOverview';
      return childState;
    }

    return route.name;
  };

  React.useEffect(() => {
    if (!navigationState) return;

    const routeName = getActiveRouteName(navigationState);
    const mappedScreen = SCREEN_TO_TOUR_MAP[routeName];

    if (mappedScreen && mappedScreen !== currentScreen) {
      setCurrentScreen(mappedScreen);
    }
  }, [navigationState, currentScreen]);

  if (!isTourEnabled || !currentScreen || !TOUR_STEPS[currentScreen]) {
    return <>{children}</>;
  }

  return (
    <View style={{ flex: 1 }}>
      <TourGuide currentScreen={currentScreen}>
        {children}
      </TourGuide>
    </View>
  );
};